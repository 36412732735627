import React, { FC } from 'react';
import { cn } from '@/lib';
import styles from './PromoBannerEllipse.module.scss';

type Props = {
  color?: string;
  className?: string;
};

export const PromoBannerEllipse: FC<Props> = (props) => {
  const { color = '#fff', className } = props;

  return (
    <div
      className={cn(styles.ellipse, className)}
      style={{ backgroundColor: color }}
    />
  );
};
